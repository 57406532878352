<template>
    <section class="cont has-check">
       <!-- 面包屑 -->
       <el-row class="crumbs-box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
             <el-breadcrumb-item>系统管理</el-breadcrumb-item>
             <el-breadcrumb-item>数据字典</el-breadcrumb-item>
          </el-breadcrumb>
       </el-row>
       <el-row class="content-box">
          <!-- 查询框 -->
          <el-row class="search-box">
             <el-row class="search-row">
                <el-row class="search-item">
                   <label v-text="$t('msg.dict_name_a')">字典名称：</label>
                   <el-input class="width-220" v-model="param.dictNameCode" :placeholder="$t('msg.dict_name')" clearable></el-input>
                </el-row>
                <el-row class="search-item">
                   <label v-text="$t('msg.client_a')">客户端：</label>
                   <el-select class="width-220"  v-model="param.client" :placeholder="$t('msg.select')">
                      <el-option
                            v-for="item in clientOpt"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                      </el-option>
                   </el-select>
                </el-row>
                <el-button class="bg-gradient" type="primary"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
                <el-button type="primary"  plain @click="handleQuery(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
             </el-row>
          </el-row>
          <!-- 主体内容 -->
          <el-row class="table-box">
             <!-- 表格按钮 -->
             <div class="table-head-btn">
                <el-row></el-row>
                <el-row class="right-btn">
                   <el-button class="bg-gradient" type="primary"  icon="el-icon-plus" style="float: right" @click="handleAdd"><span v-text="$t('msg.new_dict')">新增字典</span></el-button>
                </el-row>
             </div>
              <!-- 表格 -->
             <div class="table-box">
               <el-table
                       ref="mainTable"
                       v-loading="loading"
                       :data="tableData"
                       border fit
                       style="width: 100%"
                       :stripe="true"
                       lazy
                       row-key="id"
                       highlight-current-row
                       :load="getDictChild"
                       @row-click="(row) => handleCurrentChange(row)"
                       :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                   <el-table-column type="selection" width="55"></el-table-column>
                   <el-table-column :label="$t('msg.dict_name')" min-width="180">
                       <template slot-scope="scope">
                           <span><i v-bind:style="{color: scope.row.textColor,fontSize: '1.4rem',marginRight: '4px'}" class="icon iconfont" v-html="scope.row.icon"></i>{{scope.row.name}}</span>
                       </template>
                   </el-table-column>
                   <el-table-column prop="number" :label="$t('msg.dict_encoding')" min-width="120"></el-table-column>
                   <el-table-column prop="dictKey" :label="$t('msg.hidden_values')" min-width="120"></el-table-column>
                   <el-table-column prop="dictVal" :label="$t('msg.display_value')" min-width="120"></el-table-column>
                   <el-table-column prop="textColor" :label="$t('msg.dict_color')"></el-table-column>
                   <el-table-column prop="sortNo" :label="$t('msg.sort_number')" min-width="60"></el-table-column>
                   <el-table-column :label="$t('msg.is_init')" min-width="90">
                       <template slot-scope="scope">
                           <span style="font-size: 1.4rem;color: #1ABC9C" class="icon iconfont">{{scope.row.isInit?'&#xe7f0;':''}}</span>
                       </template>
                   </el-table-column>
                   <el-table-column prop="state" :label="$t('msg.is_it_hidden')" min-width="80"></el-table-column>
                   <el-table-column prop="remark" :label="$t('msg.remark')" min-width="100"></el-table-column>
                   <el-table-column :label="$t('msg.operating')" min-width="240" sortable fixed="right">
                       <template slot-scope="scope">
                           <el-button type="text" @click.stop="handleEdit(scope.row)" >
                               <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                           </el-button>
                           <el-button type="text" style="color: #F56C6C;" @click.stop="handleDel(scope.row.id, scope.row.parentId)" >
                               <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                           </el-button>
                           <el-button v-show="scope.row.upShow" @click.stop="handleSort(scope.row.id,'UP', scope.row.parentId)" type="text">
                               <el-link type="primary" v-text="$t('msg.up')">上移</el-link>
                           </el-button>
                           <el-button v-show="!scope.row.downShow" @click.stop="handleSort(scope.row.id,'DOWN', scope.row.parentId)" type="text" style="color: #F56C6C;">
                               <el-link type="danger" v-text="$t('msg.down')">下移</el-link>
                           </el-button>
                       </template>
                   </el-table-column>
               </el-table>
           </div>
             <!-- 分页 -->
             <pagination :total="total" :limit="param.limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
          </el-row>
          <!-- 添加/编辑弹窗 -->
          <el-dialog :title="`${action === 'add' ? '添加' : '编辑'}字典`" :close-on-click-modal="false"
                     :before-close="handleClose" :visible.sync="isShow" width="700px">
             <el-form class="form-dialog-box" :model="ruleForm" :rules="rules" ref="ruleForm">
                <el-row class="form-row">
                   <el-form-item label="所属父字典："  v-if="action === 'add'">
                      <span>{{parentDict || '根字典'}}</span>
                   </el-form-item>
                   <el-form-item label="是否隐藏：">
                      <el-switch v-model="ruleForm.state" active-value="ENABLED" inactive-value="DISABLED"></el-switch>
                   </el-form-item>
                </el-row>
                <el-row class="form-row">
                   <el-form-item label="字典名称：" prop="name">
                        <el-input v-model="ruleForm.name" :placeholder="$t('msg.dict_name')" clearable></el-input>
                     </el-form-item>
                   <el-form-item label="字典图标：">
                      <el-input v-model="ruleForm.icon" :placeholder="$t('msg.dict_icon')" clearable></el-input>
                   </el-form-item>
                  </el-row>
                <el-row class="form-row">
                   <el-form-item label="字典编码：" prop="number">
                      <el-input v-model="ruleForm.number" :placeholder="$t('msg.dict_encoding')" clearable></el-input>
                     </el-form-item>
                   <el-form-item label="字典颜色：">
                      <el-input v-model="ruleForm.textColor" :placeholder="$t('msg.dict_color')" clearable></el-input>
                   </el-form-item>
                  </el-row>
                <el-row class="form-row">
                   <el-form-item label="是否初始化：">
                      <el-checkbox v-model="ruleForm.isInit"></el-checkbox>
                   </el-form-item>
                   <el-form-item label="字典类型：">
                      <el-radio v-model="ruleForm.dictType" label="SYS">系统</el-radio>
                      <el-radio v-model="ruleForm.dictType" label="BIZ">业务</el-radio>
                   </el-form-item>
                </el-row>
                <el-row class="form-row" v-if="isAsterisk">
                   <el-form-item label="隐藏值：" prop="dictKey">
                        <el-input v-model="ruleForm.dictKey" :placeholder="$t('msg.hidden_values')" clearable></el-input>
                     </el-form-item>
                   <el-form-item label="显示值：" prop="dictVal">
                        <el-input v-model="ruleForm.dictVal" :placeholder="$t('msg.display_value')" clearable></el-input>
                     </el-form-item>
                  </el-row>
                <el-form-item label="备注：">
                   <el-input class="width-530" type="textarea"  v-model="ruleForm.remark" :placeholder="$t('msg.remark')" clearable></el-input>
                </el-form-item>
            </el-form>
             <span slot="footer" class="dialog-footer">
                <el-button  @click="resetForm" v-text="$t('msg.reset')">重置</el-button>
                <el-button class="bg-gradient" type="primary" @click="handleSave" v-text="$t('msg.save')">保存</el-button>
             </span>
          </el-dialog>
       </el-row>
    </section>
</template>
<script>
import { system } from '@/api/interface/system'
import { CustomArray } from '@/common/js/common'
export default {
  data(){
    return{
      tableData: [],       // 字典根节点列表
      maps: new Map(),     // 当前子节点列表
      loading: true,       // 开启加载中效果
      total: 0,            // 表格数据总数
      isShow: false,       // 是否显示弹窗
      action: 'add',       // 操作类型
      isAsterisk: false,   // 是否必填
      parentDict: '',      // 父字典名称
      parentId: '',        // 父字典ID
      id: '',              // 字典id
      clientOpt: [           // 客户端列表
        {
          value: '',
          label: '全部'
        }, {
          value: 'APP_HOTEL',
          label: '酒店精灵'
        }, {
          value: 'APP_HOME',
          label: '家居精灵'
        }
      ],
      param: {
        page: 1,          // 当前页
        limit: 50,        // 每页数
        level: 1,         // 层级
        dictNameCode: '', // 字典名称/编码
        client: '',       // 客户端
      },
      ruleForm: {
        state: 'DISABLED',   // 是否隐藏
        name: '',            // 字典名称
        icon: '',            // 字典图标
        number: '',          // 字典编码
        textColor: '',       // 字典颜色
        isInit: false,       // 是否初始化
        dictType: 'SYS',     // 字典类型
        dictKey: '',         // 隐藏值
        dictVal: '',         // 显示值
        remark: '',          // 备注
      },
      rules: {
        name: [{ required: true, message: '请输入字典名称', trigger: 'blur' }],
        number: [{ required: true, message: '请输入字典编码', trigger: 'blur' }],
        dictKey: [{ required: true, message: '请输入隐藏值', trigger: 'blur' }],
        dictVal: [{ required: true, message: '请输入显示值', trigger: 'blur' }]
      },
      update_success: '修改成功！',
      add_success: '添加成功！',
      del_success: "删除成功！",
      sort_success: '排序成功！',
      confirm: '确定',
      cancel: '取消',
      confirm_remove: '确定移除？',
      prompt: '提示！'
    }
  },
  mounted() {
    this.initForm = { ...this.ruleForm }
    this.getDict()
  },
  inject: ['reload'],
  methods: {
    // 获取字典根节点列表
    getDict(){
      const param = { ...this.param }
      const url = system.dictPaging
      this.$axios.post(url,param).then((res) => {
        if (res.success) {
          this.loading = false
          this.total = res.total
          let tableData = res.records
          if (tableData.length === 0) return this.tableData = []
          tableData = this.IsSortShow(tableData)
          let customArray = new CustomArray(tableData)
          this.tableData = customArray.changeKey('hasChildren', 'leaf')
        }
      })
    },
    // 点击加载当前子节点列表
    getDictChild(tree, treeNode, resolve){
      this.maps.set(tree.id, { tree, treeNode, resolve })
      const param = { parentId: tree.id, level: tree.level + 1 }
      const url = system.dict
      this.$axios.post(url, param).then(res => {
        if (res.success) {
          let childData = res.children
          if (childData.length === 0) return
          childData = this.IsSortShow(childData)
          let customArray = new CustomArray(childData)
          const newData = customArray.changeKey('hasChildren', 'leaf')
          resolve(newData)
        }
      })
    },
    // 重新加载当前子节点列表
    refreshLoadTree(parentId) {
      // 根据父级id取出对应节点数据
      if (this.maps.get(parentId)){
        const { tree, treeNode, resolve } = this.maps.get(parentId)
        this.$set(this.$refs.mainTable.store.states.lazyTreeNodeMap, parentId, [])
        if (tree) this.getDictChild(tree, treeNode, resolve)
      }else {
        this.getDict()
      }
    },
    // 搜索字典
    handleQuery(bool){
      this.param.page = 1
      if (bool) return this.getDict()
      this.param.client = ''
      this.param.dictNameCode = ''
    },
    // 改变表格显示条目数
    pageChange(num){
      this.param.limit = num
      this.getDict()
    },
    // 改变当前页时获取对应页码
    handlePaging (num) {
      this.param.page = num
      this.getDict()
    },
    // 选中当前行
    handleCurrentChange: (() => {
      let id
      return function (row) {
        if (row.id !== id){
          id = row.id
          this.id = row.id
          this.parentId = row.id
          this.parentDict = row.name
          this.isAsterisk = true
        } else {
          id = ''
          this.id = ''
          this.parentId = ''
          this.parentDict = ''
          this.isAsterisk = false
          this.$refs.mainTable.setCurrentRow();
        }
      }
    })(),
    // 添加字典
    handleAdd(){
      this.isShow = true
      this.action = 'add'
    },
    // 编辑字典
    handleEdit(row){
      for (let k in row) k in this.ruleForm && (this.ruleForm[k] = row[k])
      this.isAsterisk = row.level !== 1 ? true : false
      this.id = row.id
      this.parentId = row.parentId
      this.isShow = true
      this.action = 'edit'
    },
    // 重置表单
    resetForm(){
      this.ruleForm = { ...this.initForm }
    },
    // 保存字典
    handleSave(){
      this.$refs.ruleForm.validate(valid => {
        if (!valid) return
        let url = system.addDict
        let param = { ...this.ruleForm, parentId: this.id }
        if (this.action === 'edit'){
          url = system.editDict
          param.id = this.id
          delete param.parentId
        }
        this.$axios.post(url, param).then(res => {
          if (res.success){
            this.$message({
              showClose: true,
              message: `${ this.action === 'add' ? '添加' : '编辑' }成功`,
              type: 'success'
            })
            this.refreshLoadTree(this.parentId)
            this.handleClose()
          }
        })
      })
    },
    // 关闭弹窗
    handleClose(){
      this.isShow = false
      this.resetForm()
    },
    // 删除字典
    handleDel(id, parentId){
      this.$confirm(this.confirm_remove, this.prompt, {
        confirmButtonText: this.confirm,
        cancelButtonText: this.cancel,
        type: 'warning'
      }).then(() => {
        let ids = []; ids.unshift(id)
        const param = {ids: ids.toString()}
        const url = system.delDict;
        this.$axios.post(url,param).then(res => {
          if (res.success){
            this.$message({
              showClose: true,
              message: this.del_success,
              type: 'success'
            })
            // 删除后 要取消当前选中 并重置数据
            this.handleCurrentChange({id: this.id})
            parentId ? this.refreshLoadTree(parentId) : this.getDict()
          }
        })
      })
    },
    // 显示隐藏排序按钮
    IsSortShow(tableData){
      tableData.forEach(item => {
        if (item.sortNo === 1){
          item.upShow = false
        }else {
          item.upShow = true
        }
      })
      tableData[tableData.length-1].downShow = true
      return tableData
    },
    // 对字典列表重新排序
    handleSort(id, direction, parentId){
      const param = { id: id, direction: direction }
      const url = system.move
      this.$axios.post(url, param).then(res => {
        if (res.success){
          this.$message({
            showClose: true,
            message: this.sort_success,
            type: 'success'
          })
          parentId ? this.refreshLoadTree(parentId) : this.getDict()
        }
      })
    }
  }
}
</script>
<style scoped></style>
